<template>
  <section v-if="course != null" class="invoice-preview-wrapper">
    <b-row class="course-banner-row">
      <header
        class="w-100 course-single-page-header gradient-bg position-relative"
      >
        <div class="section-overlay">
          <div class="container">
            <div class="row m-0">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                <div class="course-single-banner-content">
                  <h3 class="page-banner-heading text-white pb-30">
                    {{ course.title }}
                  </h3>
                  <p class="page-banner-sub-heading pb-30">
                    {{ course.description }}
                  </p>
                  <p
                    class="instructor-name-certificate font-medium font-12 text-white"
                  >
                    By :
                    <span class="text-decoration-underline"
                      >Dr: Osama Akl
                    </span>
                  </p>
                  <div
                    class="course-rating d-flex align-items-center text-white"
                  >
                    <span class="font-medium font-14 mr-25">{{
                      course.students.length
                    }}</span>
                    <span class="rating-count font-14"> Students</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </b-row>

    <b-row class="invoice-preview course-section">
      <b-col cols="12" xl="9" md="8" class="order-2 order-md-1">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding p-0 p-md-25">
            <b-tabs>
              <b-tab active>
                <template #title>
                  <feather-icon icon="BookOpenIcon" />
                  <span>Course Content</span>
                </template>

                <sectionCard :sections="course.sections" />
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions order-1 order-md-2">
        <b-card class="course-single-details-right-content">
          <div class="course-info-video-img">
            <img
              :src="course.imageCover"
              style="min-height: 220px; padding-bottom: 10px"
              alt="video"
              class="img-fluid"
            />
          </div>
          <b-row class="m-0" align-h="between">
            <h5 class="text-capitalize">Price</h5>
            <b-card-text v-if="course.priceAfterDiscount" class="text-success">
              <b-badge variant="success" class="m-25">
                ${{ course.priceAfterDiscount }}
              </b-badge>
              <span
                style="
                  text-decoration: line-through red;
                  text-decoration-thickness: 1.5px;
                "
                class="text-secondary text-decoration-line-through"
              >
                {{ course.price }} $</span
              >
            </b-card-text>
            <b-card-text v-else class="text-success">
              {{ course.price }} $
            </b-card-text>
          </b-row>
          <b-row class="m-0" align-h="between">
            <h5 class="text-capitalize">Created At</h5>
            <b-card-text>
              {{ new Date(course.createdAt).toISOString().slice(0, 10) }}
              <feather-icon icon="ClockIcon" class="mr-50 text-primary" />
            </b-card-text>
          </b-row>
          <b-row class="m-0 mt-25" align-h="between">
            <h5 class="text-capitalize">Sections</h5>
            <b-card-text>
              {{ course.sections.length }}
              <feather-icon
                icon="AlignJustifyIcon"
                class="mr-50 text-primary"
              />
            </b-card-text>
          </b-row>
          <b-row class="m-0 mt-25" align-h="between">
            <h5 class="text-capitalize">Duration</h5>
            <b-card-text>
              {{ course.duration }} m
              <feather-icon icon="CalendarIcon" class="mr-50 text-primary" />
            </b-card-text>
          </b-row>
          <courseButtons class="w-100 mt-25" :course="course" />
        </b-card>
        <!-- <profile-suggestion :suggestions="course.students" /> -->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import ProfileSuggestion from "@/views/account-setting/ProfileSuggestion.vue";

import {
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import coursesApi from "@/apiServices/coursesApi";
import courseButtons from "@/views/courses pages/components/coursebuttons.vue";
import sectionCard from "@/views/courses pages/components/sectionCard.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    sectionCard,
    ProfileSuggestion,
    courseButtons,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  data() {
    return {
      course: null,
      userData: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    this.getCourse();
  },
  methods: {
    getCourse() {
      coursesApi.getCourse(this.$route.params.id).then((response) => {
        this.courses = [];
        if (response.data) {
          console.log(response.data);
          this.course = response.data?.data;
          this.previous = response.data?.data;
          console.log(
            JSON.stringify(this.previous) === JSON.stringify(this.course)
          );
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.course-single-page-header .section-overlay {
  min-height: 630px;
  display: flex;
  align-items: center;
  padding: 230px 0 130px;
}
.section-overlay {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 61, 0.27) 0%,
    rgba(1, 1, 52, 0.9) 100%
  );

  height: 100%;
  width: 100%;
}
.course-single-details-right-content {
  margin-top: -300px;
}
@media only screen and (max-width: 991.98px) {
  .course-single-details-right-content {
    margin-top: -30px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 575.98px) {
  .course-single-page-header .section-overlay {
    padding: 100px 0 30px;
  }
  .course-banner-row {
    margin-top: 0px !important;
  }
}

.course-banner-row {
  margin-right: -2rem;
  margin-left: -2rem;
  margin-top: -2rem;
}
.instructor-name-certificate {
  line-height: 17px;
  color: #9696b0;
  margin-bottom: 11px;
}
.page-banner-heading {
  position: relative;
}
.page-banner-heading {
  line-height: 44px;
  font-size: 41px;
}
.page-banner-sub-heading {
  color: rgba(255, 255, 255, 0.6);
}
.gradient-bg {
  background: linear-gradient(130deg, #ad90c1 0%, rgb(3, 0, 84) 100%),
    linear-gradient(130deg, #09007b 0%, rgba(15, 0, 66, 0) 30%),
    linear-gradient(129.96deg, rgb(255, 47, 47) 10.43%, rgb(0, 4, 96) 92.78%),
    radial-gradient(
      100% 246.94% at 100% 0%,
      rgb(255, 255, 255) 0%,
      rgba(37, 0, 66, 0.8) 100%
    ),
    linear-gradient(121.18deg, rgb(20, 0, 255) 0.45%, rgb(27, 0, 62) 100%),
    linear-gradient(154.03deg, rgb(206, 0, 0) 0%, rgb(255, 0, 61) 74.04%),
    linear-gradient(341.1deg, rgb(178, 91, 186) 7.52%, rgb(16, 0, 119) 77.98%),
    linear-gradient(222.34deg, rgb(169, 0, 0) 12.99%, rgb(0, 255, 224) 87.21%),
    linear-gradient(150.76deg, rgb(183, 213, 0) 15.35%, rgb(34, 0, 170) 89.57%);
  --gradient-overlay-bg: linear-gradient(
    180deg,
    rgba(0, 0, 61, 0.27) 0%,
    rgba(1, 1, 52, 0.9) 100%
  );
  --footer-gradient-bg: linear-gradient(130deg, #ad90c1 0%, rgb(3, 0, 84) 100%),
    linear-gradient(130deg, #09007b 0%, rgba(15, 0, 66, 0) 30%),
    linear-gradient(129.96deg, rgb(255, 47, 47) 10.43%, rgb(0, 4, 96) 92.78%),
    radial-gradient(
      100% 246.94% at 100% 0%,
      rgb(255, 255, 255) 0%,
      rgba(37, 0, 66, 0.8) 100%
    ),
    linear-gradient(121.18deg, rgb(20, 0, 255) 0.45%, rgb(27, 0, 62) 100%),
    linear-gradient(154.03deg, rgb(206, 0, 0) 0%, rgb(255, 0, 61) 74.04%),
    linear-gradient(341.1deg, rgb(178, 91, 186) 7.52%, rgb(16, 0, 119) 77.98%),
    linear-gradient(222.34deg, rgb(169, 0, 0) 12.99%, rgb(0, 255, 224) 87.21%),
    linear-gradient(150.76deg, rgb(183, 213, 0) 15.35%, rgb(34, 0, 170) 89.57%);
  background-blend-mode: overlay, normal, overlay, color-burn, screen, overlay,
    difference, difference, normal;
  display: flex;
}
.course-section {
  margin-top: -60px;
}
.course-bg {
  height: 350px;
}
.course-img {
  position: absolute;
  width: 200px;
  top: -146%;
  left: 26px;
  height: 201px;
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
